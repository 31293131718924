@media (min-width: 576px) {
	.container {
		max-width: 540px;
	}

	.f__inner {
		margin-top: 3rem;
	}
}

@media (min-width: 768px) {
	.container {
		max-width: 720px;
	}

	.container-fluid {
		padding-left: 2rem;
		padding-right: 2rem;
	}
}

@media (min-width: 992px) {
	.container,
	.my__wrap,
	.vm__wrapper {
		max-width: 960px;
	}

	.f__inner {
		margin-top: 0;
	}

	.hamburger {
		display: none;
	}

	.nav__overlay {
		position: static;
		flex-basis: auto;
		display: inline-block;
		width: auto;

		.nav__close {
			display: none;
		}
	}

	.header__nav {
		margin-top: 0;
		position: static;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: var(--white);
		box-shadow: none;
		padding: 0;
		width: auto;
	}

	.menu {
		li {
			display: inline-block;
		}

		li + li {
			margin-top: 0;
			margin-left: 1rem;
		}

		a {
			text-align: center;
			background-color: transparent;
			color: var(--body-clr);

			&:hover {
				background-color: #ecf0f5;
			}
		}

		.header__c2a {
			display: flex;
			margin-top: 0;
			width: 100%;
			text-align: center;
			padding: 0.5rem 1.5rem;
			border: 1px solid transparent;
			align-items: center;
			// background-color: var(--clr-primary);
			// color: #fff;
			border-radius: 3px;
			border-color: var(--clr-primary);

			&:hover,
			&:focus {
				background-color: var(--white);
				color: var(--clr-primary);
				border-color: var(--clr-primary);
			}
		}
	}

	.hero {
		padding: 5rem 0;

		&::after {
			content: "";
			background-color: var(--clr-primary);
			position: absolute;
			bottom: 0;
			top: 99%;
			left: 0;
			right: 0;
			width: 100%;
			height: 90px;
			z-index: -1;
		}
	}

	.credits__row {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.hero__inner {
		flex-direction: row-reverse;
	}

	.hero__art {
		flex-basis: 40%;
	}

	.credits__row {
		flex-direction: row-reverse;
	}

	.credits__content {
		flex-basis: 50%;
	}

	.credits__art {
		flex-basis: 50%;
	}

	.features__grid {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 4rem;

		.f__item + .f__item {
			margin-top: 0;
		}
	}

	.page__header {
		&::after {
			content: "";
			background-color: var(--clr-primary);
			position: absolute;
			bottom: 0;
			top: 99%;
			left: 0;
			right: 0;
			width: 100%;
			height: 60px;
			z-index: -1;
		}
	}

	.page {
		main {
			padding: 2rem;
		}
	}

	.downloads__grid {
		grid-template-columns: repeat(3, 1fr);
		display: grid;
		gap: 1.25rem;
		grid-template-areas:
			"core go basic"
			"omni stock full"
			"omni stock full";

		.core {
			grid-area: core;
		}

		.basic {
			grid-area: basic;
		}

		.omni {
			grid-area: omni;
		}

		.go {
			grid-area: go;
		}

		.stock {
			grid-area: stock;
		}

		.full {
			grid-area: full;
		}

		> .item + .item {
			margin-top: 0;
		}
	}

	.download__btn {
		max-width: 460px;
		margin-left: auto;
		margin-right: auto;

		&.has-svg {
			display: flex;
		}
	}

	.vmf__inner {
		display: flex;
		justify-content: space-between;
		align-items: center;

		> * + * {
			margin-top: 0;
		}

		> * {
			flex-basis: 30%;
		}
	}

	.finner {
		display: flex;
		justify-content: space-between;
		align-items: center;

		> * + * {
			flex-basis: 33.3%;
		}
	}

	.nikgapps__intro {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		padding: 1.5rem 0;

		img {
			margin: auto;
		}

		p {
			-ms-flex-preferred-size: 60%;
			flex-basis: 60%;
			font-size: 1rem;
			padding: 2rem;
		}
	}

	.nh__wrapper {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
	}

	.nh__item + .nh__item {
		margin-top: 0;
	}

	.nh__item {
		-ms-flex-preferred-size: 30%;
		flex-basis: 30%;
	}

	.ngf__inner {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: reverse;
		-ms-flex-direction: row-reverse;
		flex-direction: row-reverse;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.nikgapps__features__list {
		display: -ms-grid;
		display: grid;
		-ms-grid-columns: 1fr 0.75rem 1fr;
		grid-template-columns: 1fr 1fr;
		grid-gap: 0.75rem;

		li {
			padding: 1rem;
			background-color: #f7fafc;
			border-radius: 5px;
		}

		li + li {
			margin-top: 0;
		}
	}

	.post__outer {
		.post__title {
			font-size: 1.5rem;
		}
	}

	.post__share {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		text-align: left;

		.share__title {
			margin-bottom: 0;
		}
	}

	.blog-post {
		main {
			margin-top: 0;
			padding: 3rem;
		}

		.post-content {
			img {
				max-width: 450px;
			}
		}
	}
}

@media (min-width: 1200px) {
	.container,
	.my__wrap,
	.vm__wrapper {
		max-width: 1140px;
	}
}
