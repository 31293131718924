.tab__item {
	text-align: center;

	> * + * {
		margin-left: 1rem;
	}
}

.downloads main {
	background-color: #edf2f7;
}

.tab__btn {
	background-color: #fff;
	border: none;
	border: 1.5px solid var(--clr-primary);
	border-radius: 3px;
	display: inline-flex;
	align-items: center;
	padding: 0.35rem 1.5rem;
	font-size: 1.15rem;
	transition: border 180ms ease;

	&.active {
		background-color: var(--clr-primary);
		color: #fff;
	}

	&:focus,
	&:active {
		outline: none;
		border-color: #c0cedc;
	}
}

.download__btn {
	display: block;
	width: 100%;
	margin-top: 3rem;
	padding: 0.75rem;
	justify-content: center;
	background-color: var(--clr-primary);
	color: #fff;
	text-align: center;
	border-radius: 4px;
	transition: background-color 180ms ease;

	&:hover {
		background-color: #202d3a;
	}
}

.tab__packages,
.tab__addons {
	margin-top: 2.5rem;
	> h2 {
		font-size: 1.5rem;
		font-weight: 300;
		margin: 2rem 0;
		display: block;
		text-align: center;
	}
}

.downloads__grid {
	> .item + .item {
		margin-top: 1.5rem;
	}

	.item {
		background-color: #fff;
		padding: 1.5rem;
		border-radius: 3px;
		position: relative;
		box-shadow: var(--shadow-xl);
		// Inner Shadow
		// box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);

		> h3 {
			font-size: 1.125rem;
			text-transform: uppercase;
			letter-spacing: 0.5px;
			font-weight: 600;
			margin-bottom: 1rem;
			padding-bottom: 0.5rem;
			border-bottom: 1px solid #819ca9;
		}
	}

	.basic {
		&::before {
			content: "Popular";
			position: absolute;
			font-size: 0.88rem;
			display: inline-block;
			padding: 0.25rem 0.75rem;
			background-color: #146937;
			color: #fff;
			right: 1rem;
			top: 1rem;
			border-radius: 4px;
		}
	}

	ul {
		font-size: 0.95rem;
		line-height: 1.65;
		list-style-position: outside;
		padding-left: 1.25rem;
		list-style-type: disc;
	}

	li {
		padding: 0.3rem;
		position: relative;
	}

	.info__box {
		background-color: #fff;
		font-size: 0.9rem;
		padding: 0.75rem;
		border-radius: 3px;
		margin-bottom: 1rem;
		border: 1px solid #2ecc71;
		position: relative;
	}
}

.latest__changelogs {
	margin: 3rem 0;

	h3 {
		font-size: 1.25rem;
		font-weight: 600;
		margin-bottom: 1.5rem;
	}

	ul {
		background-color: #34495e;
		color: #fff;
		border-radius: 4px;
		overflow: hidden;
		font-family: var(--font-monospace);
		font-weight: 300;
	}

	li {
		padding: 0.75rem 1rem;
	}

	li + li {
		border-top: 0.5px dashed #4c6b8a;
	}
}

// Older Changelogs

.older__changelogs {
	> h3 {
		font-size: 1.25rem;
		font-weight: 600;
		margin-bottom: 1.5rem;
	}
}

// Accordion

.ui.accordion,
.ui.accordion .accordion {
	max-width: 100%;
}
.ui.accordion .accordion {
	margin: 1em 0 0;
	padding: 0;
}
.ui.accordion .accordion .title,
.ui.accordion .title {
	cursor: pointer;
}
.ui.accordion .title:not(.ui) {
	padding: 0.5em 0;
	font-size: 1em;
	color: rgba(0, 0, 0, 0.87);
}
.ui.accordion .accordion .title ~ .content,
.ui.accordion .title ~ .content {
	display: none;
}
.ui.accordion:not(.styled) .accordion .title ~ .content:not(.ui),
.ui.accordion:not(.styled) .title ~ .content:not(.ui) {
	margin: "";
	padding: 0.5em 0 1em;
}
.ui.accordion:not(.styled) .title ~ .content:not(.ui):last-child {
	padding-bottom: 0;
}
.ui.accordion .accordion .title .dropdown.icon,
.ui.accordion .title .dropdown.icon {
	display: inline-block;
	float: none;
	opacity: 1;
	width: 1.25em;
	height: 1em;
	margin: 0 0.25rem 0 0;
	padding: 0;
	font-size: 1em;
	-webkit-transition: opacity 0.1s ease, -webkit-transform 0.1s ease;
	transition: opacity 0.1s ease, -webkit-transform 0.1s ease;
	transition: transform 0.1s ease, opacity 0.1s ease;
	transition: transform 0.1s ease, opacity 0.1s ease,
		-webkit-transform 0.1s ease;
	vertical-align: baseline;
	-webkit-transform: none;
	transform: none;
}
.ui.accordion.menu .item .title {
	display: block;
	padding: 0;
}
.ui.accordion.menu .item .title > .dropdown.icon {
	float: right;
	margin: 0.21425em 0 0 1em;
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
}
.ui.accordion .ui.header .dropdown.icon {
	font-size: 1em;
	margin: 0 0.25rem 0 0;
}
.ui.accordion .accordion .active.title .dropdown.icon,
.ui.accordion .active.title .dropdown.icon {
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);
}
.ui.accordion.menu .item .active.title > .dropdown.icon {
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);
}

.ui.styled.accordion,
.ui.styled.accordion .accordion {
	border-radius: 2px;
	background: #fff;
	overflow: hidden;
	box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
		0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
.ui.styled.accordion .accordion .title,
.ui.styled.accordion .title {
	margin: 0;
	padding: 1rem;
	background-color: #fff;
	color: rgba(0, 0, 0, 0.4);
	font-weight: 700;
	border-top: 1px solid rgba(34, 36, 38, 0.15);
	-webkit-transition: background 0.1s ease, color 0.1s ease;
	transition: background 0.1s ease, color 0.1s ease;
}
.ui.styled.accordion .accordion .title:first-child,
.ui.styled.accordion > .title:first-child {
	border-top: none;
}
.ui.styled.accordion .accordion .content,
.ui.styled.accordion .content {
	margin: 0;
	padding: 0.5em 1em 1.5em;
}
.ui.styled.accordion .accordion .content {
    &.active {
        > * + * {
            margin-top: 0.75rem;
        }
    }
    & {
        padding: 0;
        padding: 0.5em 1em 1.5em;
    }
}
.ui.styled.accordion .accordion .active.title,
.ui.styled.accordion .accordion .title:hover,
.ui.styled.accordion .active.title,
.ui.styled.accordion .title:hover {
	background: 0 0;
	color: rgba(0, 0, 0, 0.87);
}
.ui.styled.accordion .accordion .active.title,
.ui.styled.accordion .accordion .title:hover {
	background: 0 0;
	color: rgba(0, 0, 0, 0.87);
}
.ui.styled.accordion .active.title {
	background: 0 0;
	color: rgba(0, 0, 0, 0.95);
}
.ui.styled.accordion .accordion .active.title {
	background: 0 0;
	color: rgba(0, 0, 0, 0.95);
}
.ui.accordion .accordion .active.content,
.ui.accordion .active.content {
	display: block;
}
.ui.fluid.accordion,
.ui.fluid.accordion .accordion {
	width: 100%;
}
.ui.inverted.accordion .title:not(.ui) {
	color: rgba(255, 255, 255, 0.9);
}
@font-face {
	font-family: Accordion;
	src: url(data:application/x-font-ttf;charset=utf-8;base64,AAEAAAALAIAAAwAwT1MvMggjB5AAAAC8AAAAYGNtYXAPfOIKAAABHAAAAExnYXNwAAAAEAAAAWgAAAAIZ2x5Zryj6HgAAAFwAAAAyGhlYWT/0IhHAAACOAAAADZoaGVhApkB5wAAAnAAAAAkaG10eAJuABIAAAKUAAAAGGxvY2EAjABWAAACrAAAAA5tYXhwAAgAFgAAArwAAAAgbmFtZfC1n04AAALcAAABPHBvc3QAAwAAAAAEGAAAACAAAwIAAZAABQAAAUwBZgAAAEcBTAFmAAAA9QAZAIQAAAAAAAAAAAAAAAAAAAABEAAAAAAAAAAAAAAAAAAAAABAAADw2gHg/+D/4AHgACAAAAABAAAAAAAAAAAAAAAgAAAAAAACAAAAAwAAABQAAwABAAAAFAAEADgAAAAKAAgAAgACAAEAIPDa//3//wAAAAAAIPDZ//3//wAB/+MPKwADAAEAAAAAAAAAAAAAAAEAAf//AA8AAQAAAAAAAAAAAAIAADc5AQAAAAABAAAAAAAAAAAAAgAANzkBAAAAAAEAAAAAAAAAAAACAAA3OQEAAAAAAQASAEkAtwFuABMAADc0PwE2FzYXFh0BFAcGJwYvASY1EgaABQgHBQYGBQcIBYAG2wcGfwcBAQcECf8IBAcBAQd/BgYAAAAAAQAAAEkApQFuABMAADcRNDc2MzIfARYVFA8BBiMiJyY1AAUGBwgFgAYGgAUIBwYFWwEACAUGBoAFCAcFgAYGBQcAAAABAAAAAQAAqWYls18PPPUACwIAAAAAAM/9o+4AAAAAz/2j7gAAAAAAtwFuAAAACAACAAAAAAAAAAEAAAHg/+AAAAIAAAAAAAC3AAEAAAAAAAAAAAAAAAAAAAAGAAAAAAAAAAAAAAAAAQAAAAC3ABIAtwAAAAAAAAAKABQAHgBCAGQAAAABAAAABgAUAAEAAAAAAAIAAAAAAAAAAAAAAAAAAAAAAAAADgCuAAEAAAAAAAEADAAAAAEAAAAAAAIADgBAAAEAAAAAAAMADAAiAAEAAAAAAAQADABOAAEAAAAAAAUAFgAMAAEAAAAAAAYABgAuAAEAAAAAAAoANABaAAMAAQQJAAEADAAAAAMAAQQJAAIADgBAAAMAAQQJAAMADAAiAAMAAQQJAAQADABOAAMAAQQJAAUAFgAMAAMAAQQJAAYADAA0AAMAAQQJAAoANABaAHIAYQB0AGkAbgBnAFYAZQByAHMAaQBvAG4AIAAxAC4AMAByAGEAdABpAG4AZ3JhdGluZwByAGEAdABpAG4AZwBSAGUAZwB1AGwAYQByAHIAYQB0AGkAbgBnAEYAbwBuAHQAIABnAGUAbgBlAHIAYQB0AGUAZAAgAGIAeQAgAEkAYwBvAE0AbwBvAG4ALgADAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA)
			format("truetype"),
		url(data:application/font-woff;charset=utf-8;base64,d09GRk9UVE8AAASwAAoAAAAABGgAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABDRkYgAAAA9AAAAS0AAAEtFpovuE9TLzIAAAIkAAAAYAAAAGAIIweQY21hcAAAAoQAAABMAAAATA984gpnYXNwAAAC0AAAAAgAAAAIAAAAEGhlYWQAAALYAAAANgAAADb/0IhHaGhlYQAAAxAAAAAkAAAAJAKZAedobXR4AAADNAAAABgAAAAYAm4AEm1heHAAAANMAAAABgAAAAYABlAAbmFtZQAAA1QAAAE8AAABPPC1n05wb3N0AAAEkAAAACAAAAAgAAMAAAEABAQAAQEBB3JhdGluZwABAgABADr4HAL4GwP4GAQeCgAZU/+Lix4KABlT/4uLDAeLa/iU+HQFHQAAAHkPHQAAAH4RHQAAAAkdAAABJBIABwEBBw0PERQZHnJhdGluZ3JhdGluZ3UwdTF1MjB1RjBEOXVGMERBAAACAYkABAAGAQEEBwoNVp38lA78lA78lA77lA773Z33bxWLkI2Qj44I9xT3FAWOj5CNkIuQi4+JjoePiI2Gi4YIi/uUBYuGiYeHiIiHh4mGi4aLho2Ijwj7FPcUBYeOiY+LkAgO+92L5hWL95QFi5CNkI6Oj4+PjZCLkIuQiY6HCPcU+xQFj4iNhouGi4aJh4eICPsU+xQFiIeGiYaLhouHjYePiI6Jj4uQCA74lBT4lBWLDAoAAAAAAwIAAZAABQAAAUwBZgAAAEcBTAFmAAAA9QAZAIQAAAAAAAAAAAAAAAAAAAABEAAAAAAAAAAAAAAAAAAAAABAAADw2gHg/+D/4AHgACAAAAABAAAAAAAAAAAAAAAgAAAAAAACAAAAAwAAABQAAwABAAAAFAAEADgAAAAKAAgAAgACAAEAIPDa//3//wAAAAAAIPDZ//3//wAB/+MPKwADAAEAAAAAAAAAAAAAAAEAAf//AA8AAQAAAAEAADfYOJZfDzz1AAsCAAAAAADP/aPuAAAAAM/9o+4AAAAAALcBbgAAAAgAAgAAAAAAAAABAAAB4P/gAAACAAAAAAAAtwABAAAAAAAAAAAAAAAAAAAABgAAAAAAAAAAAAAAAAEAAAAAtwASALcAAAAAUAAABgAAAAAADgCuAAEAAAAAAAEADAAAAAEAAAAAAAIADgBAAAEAAAAAAAMADAAiAAEAAAAAAAQADABOAAEAAAAAAAUAFgAMAAEAAAAAAAYABgAuAAEAAAAAAAoANABaAAMAAQQJAAEADAAAAAMAAQQJAAIADgBAAAMAAQQJAAMADAAiAAMAAQQJAAQADABOAAMAAQQJAAUAFgAMAAMAAQQJAAYADAA0AAMAAQQJAAoANABaAHIAYQB0AGkAbgBnAFYAZQByAHMAaQBvAG4AIAAxAC4AMAByAGEAdABpAG4AZ3JhdGluZwByAGEAdABpAG4AZwBSAGUAZwB1AGwAYQByAHIAYQB0AGkAbgBnAEYAbwBuAHQAIABnAGUAbgBlAHIAYQB0AGUAZAAgAGIAeQAgAEkAYwBvAE0AbwBvAG4ALgADAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA)
			format("woff");
	font-weight: 400;
	font-style: normal;
}
.ui.accordion .accordion .title .dropdown.icon,
.ui.accordion .title .dropdown.icon {
	font-family: Accordion;
	line-height: 1;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	font-weight: 400;
	font-style: normal;
	text-align: center;
}
.ui.accordion .accordion .title .dropdown.icon:before,
.ui.accordion .title .dropdown.icon:before {
	content: "\f0da";
}

.ui.accordion .content {
	ul {
		font-size: 15px;
		list-style-position: inside;
		border-radius: 3px;
		border: 1px solid #ddd;
	}

	li {
		padding: 0.75rem;
	}

	li + li {
		border-top: 1px solid #ddd;
	}
}
