.blog main {
	background-color: #fff;
}

.post__outer {
	padding: 1.5rem;
	border-radius: 4px;
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, 0.15);

	.post-meta {
		font-size: 0.9rem;
		color: #718096;
		text-transform: uppercase;
		letter-spacing: 0.5px;
		display: inline-block;
		margin-bottom: 1rem;
	}

	.post__title {
		font-size: 1.35rem;
		font-weight: 600;
		line-height: 1.35;
		margin-bottom: 0.75rem;

		a {
			transition: color 180ms ease;

			&:hover {
				color: var(--clr-primary);
			}
		}
	}

	.article__excerpt {
		color: #718096;
		margin-bottom: 1rem;
	}

	.read-more {
		display: inline-block;
		color: #3498db;

		&:hover {
			color: var(--clr-primary);
			text-decoration: underline;
		}
	}
}

.post__outer + .post__outer {
	margin-top: 2rem;
}

.post__share {
	margin: 3rem 0;

	.share__title {
		font-size: 1rem;
		margin-bottom: 1rem;
		text-align: center;
		letter-spacing: 1px;
		text-transform: uppercase;
		margin-right: 1rem;
	}

	.share-list {
		text-align: center;
	}

	li {
		display: inline-block;
	}

	a {
		display: inline-block;
		color: #fff;
		line-height: 1;
		padding: 0.5rem 1rem;
		border-radius: 2px;

		&:hover {
			filter: brightness(110%);
		}
	}

	.share-facebook {
		background-color: #4267b2;
	}

	.share-twitter {
		background-color: #1da1f2;
	}

	.share-whatsapp {
		background-color: #25d366;
	}
}

.blog-post {
	.page__header {
		.post-title {
			text-align: center;
			display: block;
			font-size: 2rem;
			font-weight: 600;
			line-height: 1.35;
			margin-bottom: 0.5rem;
		}

		.post-meta {
			display: block;
			text-align: center;
			color: #6c8c9b;
		}
	}

	.post-content {
		line-height: 1.65;
		> * + * {
			margin-top: 1.5rem;
		}

		h1,
		h2,
		h3 {
			font-weight: 600;
			padding-bottom: 0.25rem;
			border-bottom: 1px solid rgba(0, 0, 0, 0.11);
		}

		h1 {
			font-size: 1.75rem;
		}

		h2 {
			font-size: 1.5rem;
		}

		h3 {
			font-size: 1.35rem;
		}

		ul {
			list-style: disc outside;
			padding-left: 1.5rem;
		}

		li {
			padding: 0.25rem 0;
			font-weight: 500;
		}

		a {
			color: #3498db;
			text-decoration: underline;
		}

		ol {
			list-style: decimal inside;
			padding-left: 2rem;
		}

		img {
			max-width: 100%;
			margin: 0 auto;
		}

		.divider {
			padding: 0 0 0 0;
			list-style: none outside;

			li {
				padding: 1rem;
				font-weight: 400;
			}

			li + li {
				border-top: 1px solid rgba(34, 36, 38, 0.15);
			}
		}
	}
}

.post__comments {
	margin-top: 2rem;
}

.no__comments {
	display: block;
	margin-top: 2rem;
	text-align: center;
	color: var(--text-lighter);
	background-color: #d6dfe9;
	padding: 1rem;
	border-radius: 3px;
}

#markdown-toc {
	background:#fafafa;
	padding:1rem 1.5rem .25rem 2.5rem;
	color:black;
	font-size:14px;
	width:fit-content;
	line-height:1.8;
	margin-bottom:1rem;
	border-radius:4px;
	border: 0.5px solid #aaa;
}

// Adding 'Contents' headline to the TOC
#markdown-toc::before {
    content: "Table of Contents";
	font-weight:500;
	color:#555;
	text-transform:uppercase;
	font-size:1rem;
	margin-left:-1rem;
}

// Using circle instead of bullets for listing
#markdown-toc ul {
    list-style: circle;
}

#markdown-toc a {
	font-weight:500;
	color:#555;
	font-size:.90rem;
	text-decoration: none;
	list-style-type: circle;
}